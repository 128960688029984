import paddock from './images/farm_paddock.jpg';
import valley from './images/green_rural_valley_New_Zealand.JPG'
import cloud from './images/Clouds.png'
import logo from './images/Cochrane-Family-Farms_colour.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <img src={logo} className="Logo" alt="cochrane logo" />
          <a className="Email" href="mailto:grant@cochranefarms.co.nz">grant@cochranefarms.co.nz</a>
      </header> 
      {/* <img src={cloud} className="Cloud" alt="cloud" /> */}
      <div className="Background-img">
        <div className="Valley" style={{backgroundImage: `url(${valley})`}}> </div>
        <div className="Paddock" style={{backgroundImage: `url(${paddock})`}}> </div>
      </div>
    </div>
  );
}

export default App;
